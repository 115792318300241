<template>
  <div class="mb-4 certification-container">
    <div class="flex place-items-center text-primary" @click="toggleCheckbox">
      <Icon :icon-name="iconName" />
      <label 
        :class="{'font-bold': isChecked}" 
        class="ml-2.5 text-primary"
      >
        {{ schema.customOptions.checkboxLabel }}
      </label>
    </div>

    <div v-if="isChecked" class="flex date-picker-container place-items-center text-primary">
      <label class="font-bold">{{ options.label }}</label>

      <div class="w-full date-picker form-control">
        <VueDatePicker
          ref="picker"
          v-model="value[currentKey].year"
          :color="options.color"
          :format="options.format"
          :format-header="options.formatHeader"
          :locale="{lang: $i18n.locale}"
          :placeholder="schema.placeholder"
          :type="options.dateType"
          :no-calendar-icon="options.noCalendarIcon"
          :min-date="options.minDate"
          :max-date="options.maxDate"
          :visible-years-number="options.visibleYearsNumber"
          :disabled="options.disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator';
  import { VueDatePicker } from '@mathieustan/vue-datepicker';

  import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

  export default {
    name: 'FieldCertification',
    components: {
      VueDatePicker
    },
    mixins: [abstractField],
    data: () => {
      return {
        isChecked: false
      };
    },
    computed: {
      iconName() {
        return this.isChecked ? 'CheckSquareIcon' : 'SquareIcon';
      },
      options() {
        const props = this.schema.customOptions;
        const opts = {
          color: '#09415c',
          dateType: props.dateType,
          disabled: props.hasOwnProperty('disabled') ? props.disabled : false,
          label: props.datePickerLabel,
          format: props.format,
          formatHeader: props.formatHeader ? props.formatHeader : 'dddd DD MMM',
          maxDate: props.maxDate ? props.maxDate : null,
          minDate: props.minDate ? props.minDate : null,
          noCalendarIcon: props.hasOwnProperty('noCalendarIcon') ? props.noCalendarIcon : false,
          visibleYearsNumber: props.visibleYearsNumber ? props.visibleYearsNumber : null
        };

        return opts;
      },
      keys() {
        return Object.keys(this.value);
      },
      currentKey() {
        return this.keys.find(k => this.value[k].title === this.schema.customOptions.checkboxLabel);
      }
    },
    mounted() {
      this.initCheckbox();
    },
    methods: {
      initCheckbox() {
        if (this.value && this.keys.length > 0) {
          this.isChecked = this.currentKey >= 0;
        }
      },
      toggleCheckbox() {
        const isChecked = !this.isChecked;

        if (isChecked) {
          if (!this.value) {
            this.value = {};
          }

          const newKey = this.keys.length;
          this.value = {
            ...this.value,
            [newKey]: {
              title: this.schema.customOptions.checkboxLabel,
              year: null
            }
          };
        } else {
          delete this.value[this.currentKey];
        }

        this.isChecked = isChecked;
      }
    },
  };
</script>

<style lang='scss'>
.certification-container {
  flex-wrap: wrap;
  justify-content: flex-start;

  .text-primary {
    label {
      color: theme('colors.primary');
      font-weight: normal;
    }
  }

  label {
    &.font-bold {
      font-weight: 700;
    }
  }

  .date-picker-container {
    .date-picker {
      padding: 0 1rem 0 .5rem;
      width: 5rem;
    }

    label {
      @apply whitespace-nowrap;
    }

    input {
      @apply text-right;

      font-size: .875rem !important; // Override package font-size which use !important
    }
  }
}
</style>