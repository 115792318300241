import { Roles } from '@/helpers';
import i18n from '@/i18n';

const EmployerDashboard = () => import('@/components/employer/EmployerDashboard.vue');
const CompanyProfile = () => import('@/components/employer/CompanyProfile.vue');
const JobOffersList = () => import('@/components/employer/JobOffersList.vue');
const NewJobOffer = () => import('@/components/employer/NewJobOffer.vue');

const routes = [
  {
    path: '/dashboard-employeur',
    name: 'employer-dashboard',
    component: EmployerDashboard,
    meta: {
      authorize: [Roles.employer]
    },
    children: [
      {
        path: '/offre-emploi/:offerId',
        name: 'job-offer',
        props: true,
        component: EmployerDashboard,
        meta: {
          title: i18n.t('router.employer.edit'),
          authorize: [Roles.employer]
        },
      },
    ]
  },
  {
    path: '/mon-entreprise',
    name: 'company-profile',
    component: CompanyProfile,
    meta: {
      title: i18n.t('router.employer.profile'),
      authorize: [Roles.employer]
    }
  },
  {
    path: '/mes-fiches-de-poste',
    name: 'job-offers',
    component: JobOffersList,
    meta: {
      title: i18n.t('router.employer.job_offer_list'),
      authorize: [Roles.employer]
    }
  },
  {
    path: '/nouvelle-fiche-de-poste',
    name: 'new-job-offer',
    component: NewJobOffer,
    meta: {
      title: i18n.t('router.employer.new'),
      authorize: [Roles.employer]
    }
  }
];

export default routes;
