import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/modules';
import { NavigationGuard } from '@/services/NavigationGuard.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: window._env_.BASE_URL,
  routes: [...routes]
});

const DEFAULT_TITLE =  window._env_.VUE_APP_TITLE;

// Vue router navigation guards
router.beforeEach((to, from, next) => {
  // NavigationGuard service
  NavigationGuard(to, next);
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});


export default router;
