<template>
  <div class="select-container w-full relative" tabindex="0" @focusout="isSelectOpen = false">
    <div 
      class="form-control select-selection" 
      :class="{'is-open': isSelectOpen, 'disabled': options.length === 0, 'selected': value}"
      @click="toggleSelect"
    >
      <span 
        v-if="schema.placeholder && (!value || value.length === 0)" 
        class="text-neutralgrey400 whitespace-nowrap overflow-ellipsis overflow-x-hidden"
      >
        {{ schema.placeholder }}
      </span>

      <span v-else class="text-primary">
        {{ getSelectionLabel(value) }}
      </span>

      <span class="icon-container ml-auto">
        <Icon icon-name="ChevronDownIcon" />
      </span>
    </div>

    <div 
      v-if="isSelectOpen" 
      class="select-options border-neutralgrey300 text-base leading-6 bg-white rounded shadow-dropdown"
    >
      <div class="option cursor-pointer text-neutralgrey400" @click="resetSelection">
        {{ schema.placeholder }}
      </div>

      <div 
        v-for="(option, i) in options" 
        :key="`select-option-${i}`" 
        class="option cursor-pointer text-neutralgrey900"
        :class="{'is-selection': option === value}"
        @click="onSelect(option)"
      >
        {{ getOptionLabel(option) }}
      </div>
    </div>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator';

  export default {
    name: 'FieldCustomSelect',
    mixins: [ abstractField ],
    data: () => {
      return {
        isSelectOpen: false
      };
    },
    computed: {
      valueKey() {
        return this.schema.customOptions.valueKey;
      },
      labelKey() {
        return this.schema.customOptions.labelKey;
      },
      options() {
        return this.schema.customOptions.options;
      }
    },
    methods: {
      toggleSelect() {
        if (this.options.length) {
          this.isSelectOpen = !this.isSelectOpen;
        }
      },
      onSelect(opt) {
        this.value = this.valueKey && this.valueKey.length > 0 ? opt[this.valueKey] : opt;
        this.toggleSelect();
      },
      getOptionLabel(opt) {
        return this.labelKey && this.labelKey.length > 0 ? opt[this.labelKey] : opt;
      },
      getSelectionLabel(selection) {
        if (this.valueKey && this.valueKey.length > 0) {
          const matchingOption = this.options.find(opt => opt[this.valueKey] === selection);
          return matchingOption ? 
            matchingOption[this.labelKey] : this.$translations.shared.forms.label_input.no_matching_option;
        } else {
          return selection;
        }
      },
      resetSelection() {
        this.value = null;
        this.toggleSelect();
      }
    }
  };
</script>

<style lang='scss' scoped>
.select-selection {
  cursor: pointer;

  &.is-open {
    .icon-container {
      transform: rotate(180deg);
    }
  }

  &.selected {
    background-color: theme('colors.neutralgrey50');
    color: theme('colors.neutralgrey900');

    span {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      padding-right: .5rem;
    }
  }

  &.disabled {
    color: theme('colors.neutralgrey300');
    border-color: theme('colors.neutralgrey300');
    pointer-events: none;
    cursor: not-allowed;
  }

  .icon-container {
    transition: transform 200ms ease-out;
  }
}

.select-options {
  position: absolute;
  bottom: -.5rem;
  left: 0;
  width: 100%;
  padding: 0;
  max-height: 12.8125rem;
  overflow-y: scroll;
  transform: translateY(100%);
  border-width: .0625rem;
  border-style: solid;
  z-index: 2;
}

.option {
  font-size: .875rem;
  line-height: 1rem;
  padding: .75rem;

  &:hover,
  &.is-selection {
    background-color: theme('colors.neutralgrey50');
  }
}
</style>