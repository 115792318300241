<template>
  <div class="app">
    <router-view />

    <CookieBanner />
  </div>
</template>

<script>
  import CookieBanner from '@/components/shared/utils/CookieBanner';
  import { api } from '@/helpers';
  import EventBus from '@/services/EventBus';

  export default {
    name: 'App',
    components: {
      CookieBanner
    },
    // Add this script tag to head to handle dynamic env variables
    head: {
      script: [
        {
          type: 'text/javascript',
          src: `https://maps.googleapis.com/maps/api/js?key=${window._env_.VUE_APP_GOOGLE_API_KEY}&libraries=places`,
          async: false,
          body: false
        }
      ],
    },
    mounted() {
      this.getCsrfToken();

      EventBus.$on('User::ResetSession', this.onResetSession);
    },
    methods: {
      hasCsrfTokenCookie() {
        return document.cookie.replace(/(?:(?:^|.*;\s*)CSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1') !== '';
      },
      getCsrfToken() {
        if (!this.hasCsrfTokenCookie()) {
          api.get('/csrf_tokens');
        }
      },
      onResetSession() {
        // Reset user session after a 401 occured
        this.$store.commit('resetStep');
        this.$store.commit('resetMaxStep');
        this.$store.commit('resetUser');

        if (this.$route.name !== 'login') {
          this.$router.push({name: 'login'});
        }
      }
    }
  };
</script>

