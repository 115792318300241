import { Roles } from '@/helpers';
import i18n from '@/i18n';

const routes = [
  {
    path: '/dashboard-actif',
    name: 'worker-dashboard',
    component() {
      return import('@/components/worker/WorkerDashboard.vue');
    },
    meta: {
      title: i18n.t('router.worker.dashboard'),
      authorize: [Roles.worker]
    }
  },
  {
    path: '/mon-profil',
    name: 'worker-profile',
    component() {
      return import('@/components/worker/WorkerProfile.vue');
    },
    meta: {
      title: i18n.t('router.worker.profile'),
      authorize: [Roles.worker]
    }
  }
];

export default routes;
