import i18n from '@/i18n';

const routes = [
  {
    path: '/connexion',
    name: 'login',
    component() {
      return import('@/components/shared/forms/Login.vue');
    },
    meta: {
      title: i18n.t('router.log_in_sign_up.login'),
      authorize: []
    }
  },
  {
    path: '/inscription-actif',
    name: 'worker-sign-up',
    component() {
      return import('@/components/worker/WorkerSignUp.vue');
    },
    meta: {
      title: i18n.t('router.log_in_sign_up.worker_sign_up'),
      authorize: []
    }
  },
  {
    path: '/inscription-employeur',
    name: 'employer-sign-up',
    component() {
      return import('@/components/employer/EmployerSignUp.vue');
    },
    meta: {
      title: i18n.t('router.log_in_sign_up.employer_sign_up'),
      authorize: []
    }
  },
  {
    path: '/confirmation-email',
    name: 'email-confirmation',
    component() {
      return import('@/components/shared/forms/EmailConfirmation.vue');
    },
    meta: {
      title: i18n.t('router.log_in_sign_up.email_confirmation'),
      authorize: []
    }
  },
  {
    path: '/mot-de-passe-oublie',
    name: 'forgot-password',
    component() {
      return import('@/components/shared/forms/ForgotPassword.vue');
    },
    meta: {
      title: i18n.t('router.log_in_sign_up.forgot_password'),
      authorize: []
    }
  },
  {
    path: '/nouveau-mot-de-passe',
    name: 'new-password',
    component() {
      return import('@/components/shared/forms/PasswordReset.vue');
    },
    meta: {
      title: i18n.t('router.log_in_sign_up.new_password'),
      authorize: []
    }
  }
];

export default routes;
