<template>
  <div class="w-full date-picker form-control">
    <VueDatePicker
      ref="picker"
      v-model="value"
      :color="options.color"
      :format="options.format"
      :format-header="options.formatHeader"
      :locale="{lang: $i18n.locale}"
      :placeholder="schema.placeholder"
      :type="options.dateType"
      :no-calendar-icon="options.noCalendarIcon"
      :min-date="options.minDate"
      :max-date="options.maxDate"
      :visible-years-number="options.visibleYearsNumber"
      :disabled="options.disabled"
    >
      <template #input-icon>
        <Icon icon-name="CalendarIcon" />
      </template>
    </VueDatePicker>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator';
  import { VueDatePicker } from '@mathieustan/vue-datepicker';

  import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

  export default {
    name: 'FieldDatePicker',
    components: {
      VueDatePicker
    },
    mixins: [ abstractField ],
    computed: {
      options() {
        const props = this.schema.customOptions;
        const opts = {
          color: '#09415c',
          dateType: props.dateType,
          disabled: props.hasOwnProperty('disabled') ? props.disabled : false,
          format: props.format,
          formatHeader: props.formatHeader ? props.formatHeader : 'dddd DD MMM',
          maxDate: props.maxDate ? props.maxDate : null,
          minDate: props.minDate ? props.minDate : null,
          noCalendarIcon: props.hasOwnProperty('noCalendarIcon') ? props.noCalendarIcon : false,
          visibleYearsNumber: props.visibleYearsNumber ? props.visibleYearsNumber : null
        };

        return opts;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form-control {
    &.date-picker {
      padding: .55rem 1rem;
    }
  }
</style>