import i18n from '@/i18n';

const Home = () => import('@/components/Home.vue');
const DesignSystem = () => import('@/components/DesignSystem.vue');

let routes = [
  { path: '*', redirect: '/' },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: i18n.t('router.app.home'),
      authorize: []
    }
  }
];

if (window._env_.NODE_ENV === 'development') {
  routes = routes.concat({
    path: '/design-system',
    name: 'design-system',
    component: DesignSystem,
    meta: {
      authorize: []
    }
  });
}

export default routes;
