import Vue from 'vue';
import i18n from '@/i18n';
import App from '@/App.vue';
import router from '@/router';
import VueHead from 'vue-head';
import store from '@/store';
import VueClipboard from 'vue-clipboard2';
import 'vue-form-generator/dist/vfg.css';
import '@/assets/scss/app.scss';
// Icons
import Icon from './components/shared/images/Icon.vue';
Vue.component('Icon', Icon);

// Sentry integration
import * as Sentry from '@sentry/vue';

Sentry.init({
  Vue,
  dsn: window._env_.VUE_APP_SENTRY_DSN,
  environment: window._env_.VUE_APP_SENTRY_ENV,
  ignoreErrors: [/.*unauthorized access.*/, /.*code 401.*/],
  integrations: [
    new Sentry.browserTracingIntegration({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [window._env_.VUE_APP_API_BASE_URL]
    }),
    new Sentry.Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(window._env_.VUE_APP_SENTRY_TRACES_SAMPLE),
});

// Forms
import VueFormGenerator, { validators } from 'vue-form-generator';
// -- Register custom fields
import FieldDatePicker from './components/shared/fields/FieldDatePicker.vue';
import FieldGooglePlaces from './components/shared/fields/FieldGooglePlaces.vue';
import FieldImageUpload from './components/shared/fields/FieldImageUpload.vue';
import FieldCustomSelect from './components/shared/fields/FieldCustomSelect.vue';
import FieldCertification from './components/shared/fields/FieldCertification.vue';

Vue.component('FieldDatePicker', FieldDatePicker);
Vue.component('FieldGooglePlaces', FieldGooglePlaces);
Vue.component('FieldImageUpload', FieldImageUpload);
Vue.component('FieldCustomSelect', FieldCustomSelect);
Vue.component('FieldCertification', FieldCertification);

Vue.use(VueFormGenerator, { validators });
Vue.use(VueClipboard);
Vue.use(VueHead);

Vue.config.productionTip = false;

// Shortcut to translations access
Vue.prototype.$translations = i18n.messages[i18n.locale];
Vue.prototype.$locale = i18n.locale;

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    // Localize common vue form validators
    const localizedValidators = this.$translations.shared.forms.vue_form_validators;
    validators.resources.fieldIsRequired = localizedValidators.required;
    validators.resources.invalidFormat = localizedValidators.invalid_format;
    validators.resources.textTooSmall = localizedValidators.text_too_short;
    validators.resources.textTooBig = localizedValidators.text_too_long;
  },
  render(h) {
    return h(App);
  }
}).$mount('#app');

// Dynamic page title based on environment
window.document.title = window._env_.VUE_APP_TITLE;
