import axios from 'axios';
import { toast } from 'tailwind-toast';

import { languages } from '@/locales/';
import EventBus from '@/services/EventBus';

const baseURL = window._env_.VUE_APP_API_BASE_URL;

export const axiosInstance = axios.create({ baseURL: `${baseURL}/api/v1` });
axiosInstance.defaults.xsrfCookieName = 'CSRF-TOKEN';
axiosInstance.defaults.xsrfHeaderName = 'X-CSRF-Token';
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const errorStatus = error.response.status;
      const messages = languages.fr.shared.axios_errors;
      const errorMessage = messages[errorStatus] ? messages[errorStatus] : messages.default;

      if (errorStatus === 401) {
        EventBus.$emit('User::ResetSession');
      }

      if (!error.response.config.hideToast) {
        toast()
          .danger('', errorMessage)
          .with({
            shape: 'square',
            duration: 5000,
            speed: 200,
            positionX: 'end',
            positionY: 'bottom',
            icon: 'hidden',
            color: 'bg-danger'
          })
          .show();
      }
    }

    return [error, Promise.reject(error)];
  }
);

export default axiosInstance;
