import store from '@/store';
import routes from '@/router/modules';
import { Roles } from '@/helpers';

// NavigationGuard service
// redirect depending on user role
export const NavigationGuard = (to, next) => {
  const { authorize } = to.meta;
  const currentUser = store.state.user;
  let userRoles = [];

  // Check if a user is logged in
  if (currentUser.id) {
    if (currentUser.role === Roles.employer) {
      userRoles.push(Roles.employer);
    } else {
      userRoles.push(Roles.worker);
    }
  } else {
    userRoles.push(Roles.visitor);
  }

  // Redirect to Login component if a non logged in user attempt to access unauthorized url
  if (authorize) {
    if (!userRoles.includes(Roles.worker || Roles.employer) && authorize.length && !currentUser) {
      routes.forEach(route => {
        if (route.name === 'login') {
          return next(route.path);
        }
      });
    } else if (to.name === 'home' && currentUser.id) {
      // If user is logged in, redirect to dashboard except on log out
      const routeName = currentUser.role === Roles.worker ? 'worker-dashboard' : 'job-offers';
      next(routes.find(r => r.name === routeName));
    } else {
      next();
    }

    // Check if route is restricted by role
    let authorizeRole = false;
    userRoles.forEach((role, i) => {
      if (authorize.includes(role)) {
        authorizeRole = true;
      }
    });

    if (authorize.length && !authorizeRole) {
      // Role not authorized so redirect to home page
      routes.forEach(route => {
        if (route.name === 'login') {
          return next(route.path);
        }
      });
    }
  }
};
