<template>
  <VueGoogleAutocomplete
    :id="schema.customOptions.id"
    classname="form-control w-full bg-transparent"
    :country="schema.customOptions.country"
    :types="schema.customOptions.types"
    :placeholder="schema.placeholder"
    :value="schema.customOptions.defaultValue ? schema.customOptions.defaultValue : null"
    @placechanged="schema.customOptions.inputChanged"
    @change="onChange"
  />
</template>

<script>
  import { abstractField } from 'vue-form-generator';
  import VueGoogleAutocomplete from 'vue-google-autocomplete';

  // Native vue-form-generator googleAddress field does not allow types specification
  // API config is hard-coded
  export default {
    name: 'FieldGooglePlaces',
    components: {
      VueGoogleAutocomplete
    },
    mixins: [ abstractField ],
    methods: {
      onChange(value) {
        if (value.length === 0) {
          // Update model in form when user clears the field
          this.schema.customOptions.inputChanged(null);
        }
      }
    }
  };
</script>