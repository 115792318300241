import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { languages } from '@/locales/index.js';
import { defaultLocale } from '@/locales/index.js';
const messages = Object.assign(languages);

Vue.use(VueI18n);

// Handle translations
export default new VueI18n({
  fallbackLocale: 'fr',
  locale: defaultLocale,
  messages
});
