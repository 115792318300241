// Steps navigation (store current and max step )
const state = {
  currentStep: 0,
  maxStep: 0,
  showAboutModal: null
};

const mutations = {
  setStep(state, currentStep) {
    state.currentStep = currentStep;
  },
  resetStep(state) {
    state.currentStep = null;
  },
  setMaxStep(state, maxStep) {
    state.maxStep = maxStep;
  },
  resetMaxStep(state) {
    state.maxStep = null;
  },
  setShowAboutModal(state, showAboutModal) {
    state.showAboutModal = showAboutModal;
  },
  resetShowAboutModal(state) {
    state.showAboutModal = null;
  }
};

export default {
  state,
  mutations
};
