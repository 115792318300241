<template>
  <div>
    <CookieLaw 
      :button-text="translations.accept_button"
      button-class="button"
      :button-decline="true"
      :button-decline-text="translations.decline_button"
      :message="translations.content"
      storage-type="cookies"
      theme="base" 
      @decline="onDecline"
    />
  </div>
</template>

<script>
  import CookieLaw from 'vue-cookie-law';

  export default {
    name: 'CookieBanner',
    components: {
      CookieLaw
    },
    computed: {
      translations() {
        return this.$translations.shared.cookie_banner;
      }
    },
    methods: {
      onDecline() {
        console.log('Oh no, cookie got declined !');
      }
    }
  };
</script>