import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Stores
import user from '@/store/modules/user';
import navigation from '@/store/modules/navigation';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    navigation
  },
  plugins: [createPersistedState()]
});

export default store;
