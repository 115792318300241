<template>
  <div class="w-full text-center image-upload-container" :class="{'relative': hasDeleteButton}">
    <div 
      :class="{'placeholder': !displayedPicture, 'bg-no-repeat bg-cover bg-center': displayedPicture}" 
      class="image-upload bg-white mx-auto mb-3 cursor-pointer" 
      :style="{backgroundImage: displayedPicture ? `url('${displayedPicture}')` : 'none'}"
      @click="openLibrary"
    >
      <input
        ref="uploadInput"
        class="hidden"
        type="file"
        accept="image/*"
        :model="schema.model"
        name="avatar"
        @change="onImageInput"
      >

      <div v-if="!displayedPicture">
        <Icon icon-name="CameraIcon" />

        <p class="mx-auto">
          {{ placeholder }}
        </p>
      </div>
    </div>

    <button 
      v-if="displayedPicture && hasDeleteButton" 
      type="button" 
      class="delete-image-button" 
      @click="deletePicture"
    >
      <Icon icon-name="Trash2Icon" />
    </button>
    
    <button 
      type="button" 
      class="edit-button shadow-input" 
      @click="openLibrary"
    >
      {{ translations.edit_picture }}
    </button>

    <span v-if="schema.required" class="text-xs text-danger block">
      {{ translations.required }}
    </span>
    <span v-if="tooHeavyError" class="text-base text-danger">
      {{ translations.too_heavy }} {{ uploadMaxSize }}
    </span>
  </div>
</template>

<script>
  import { api } from '@/helpers';
  import { abstractField } from 'vue-form-generator';

  export default {
    name: 'FieldImageUpload',
    mixins: [ abstractField ],
    data: () => {
      return {
        imagePreview: null,
        tooHeavyError: false
      };
    },
    computed: {
      translations() {
        return this.$translations.shared.forms.image_upload;
      },
      displayedPicture() {
        return this.imagePreview ? this.imagePreview : this.schema.customOptions.defaultValue;
      },
      placeholder() {
        const schemaPlaceholder = this.schema.placeholder;
        return schemaPlaceholder && schemaPlaceholder.length > 0 ? 
          schemaPlaceholder : this.translations.profile_placeholder;
      },
      uploadMaxSize() {
        return `${this.schema.customOptions.uploadSizeToMo} mo`;
      },
      hasDeleteButton() {
        // Set default for hasDeleteButton
        return this.schema.customOptions.hasOwnProperty('hasDeleteButton') ? 
          this.schema.customOptions.hasDeleteButton : false;
      }
    },
    methods: {
      openLibrary() {
        this.$refs.uploadInput.click();
      },
      onImageInput() {
        const input = this.$refs.uploadInput;
        const files = input.files;
        this.tooHeavyError = false;

        if (files && files[0].size < this.schema.customOptions.maxFileUpload) {
          this.imagePreview = URL.createObjectURL(files[0]);
          this.schema.onImageChange(files[0]);
          this.schema.customOptions.isValid = true;
        } else {
          this.tooHeavyError = true;
          this.schema.customOptions.isValid = this.schema.required ? false : true;
        }
      },
      async deletePicture() {
        this.$refs.uploadInput.value = null;
        this.schema.onImageChange(null);
        this.imagePreview = null;
        this.schema.customOptions.defaultValue = null;
        this.schema.customOptions.isValid = this.schema.required ? false : true;

        const resumeId = this.$store.state.user.resumeAttributes.id;
        const experienceId = this.schema.customOptions.experienceId;
        await api.put(`/resumes/${resumeId}/experiences/${experienceId}`, {
          remove_image: true
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .image-upload {
    width: 9.375rem;
    height: 9.375rem;
    border-radius: .25rem;
  }

  .image-upload-container {
    .delete-image-button {
      @apply p-0 absolute;

      display: block;
      right: .5rem;
      top: .5rem;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;

      .feather {
        @apply m-0 mx-auto text-primary;

        width: 1.15rem;
      }
    }
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme('colors.neutralgrey100');
    border: .125rem dashed theme('colors.neutralgrey600');
    color: theme('colors.primary');

    .feather {
      width: 2.25rem;
      height: auto;
      margin: 0 auto .5rem;
    }

    div {
      font-size: .75rem;
      line-height: 1.125rem;
    }

    p {
      max-width: 69%;
    }
  }
</style>