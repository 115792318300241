<template>
  <component :is="iconComponent" />
</template>

<script>
  import * as icons from 'vue-feather-icons';

  export default {
    name: 'Icon',
    props: {
      iconName: {
        type: String,
        required: true
      }
    },
    computed: {
      iconComponent() {
        return icons[this.iconName];
      }
    }
  };
</script>