import router from '@/locales/fr/router.json';
import home from './fr/home.json';
import logInSignUp from './fr/log-in-sign-up.json';
import shared from './fr/shared.json';
import workerDashboard from './fr/worker-dashboard.json';
import employerDashboard from './fr/employer-dashboard.json';

export const defaultLocale = 'fr';

export const languages = {
  fr: {
    router,
    home,
    logInSignUp,
    shared,
    workerDashboard,
    employerDashboard
  }
};
