// Current user store
const state = null;

const mutations = {
  setUser(state, user) {
    Object.assign(state, user);
  },
  updateUser(state, user) {
    Object.assign(state, user);
  },
  resetUser(state) {
    Object.keys(state).forEach(key => {
      delete state[key];
    });
  },
  addResumeToWorker(state, resume) {
    delete resume.userAttributes;
    state.resumeAttributes = Object.assign({}, resume);
  }
};

const getters = {
  getResumeAttributes: state => state.resumeAttributes
};

export default {
  state,
  mutations,
  getters
};
